import { Box } from '@mui/material'
import React from 'react'
import { HeaderText } from './HeaderText'
import { TextCompany } from './TextCompany'

export const AboutCompany = ({type}) => {
    return (
        <Box
            sx={{
                maxWidth: "1200px",
                paddingRight: "134px",
                display: "flex",
                flexDirection: type,
                '@media (max-width: 992px)': {
                    padding: "0",
                }
            }}
        >
            <Box
                sx={{
                    minWidth: "32%"
                }}
            >
                <HeaderText size="48px">
                    О компании
                </HeaderText>
            </Box>
            <Box
                sx={{
                    padding: "0 20px",
                }}
            >
                <TextCompany >
                    Наша команда это разработчики и действующие предприниматели. Такой симбиоз позволяет нам создавать программные решения, которые максимально учитывают специфику бизнес процессов.
                </TextCompany>
                <TextCompany>
                    Сейчас наше основное направление это создание собственных saas решений для микро и малого бизнеса.
                </TextCompany>
                <TextCompany>
                    Это система учёта и коммуникации с клиентами, платформа для организация дистанционной торговли на базе чат-ботов , а так же система контроля уведомлений в популярных мессенджерах.
                </TextCompany>
                <TextCompany>
                    Мы работаем с современными технологиями, такими как Python , React , Redux , Yandex Cloud Functions , а в качестве реляционных баз данных используем российские решения: Yandex DB
                </TextCompany>
                <TextCompany>
                    В нашей команде сложилась уникальная атмосфера лояльности к клиентам, ориентированная на поддержание партнерства. Каждый сотрудник АЙТИПАРА играет значимую роль в формировании ценностей, которыми мы очень дорожим                </TextCompany>
            </Box>
        </Box>
    )
}
