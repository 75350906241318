import React from 'react'

import "@fontsource/russo-one";
import { Typography } from '@mui/material';

export const HeaderText = ({ children, size, align }) => {
    return (
        <Typography
            component="h1"
            sx={{
                textAlign: align,
                opacity: 1,
                fontSize: size,
                lineHeight: "1.1",
                fontFamily: "Russo One",
                fontWeight: 500
            }}
        >
            {children}
        </Typography>
    )
}
