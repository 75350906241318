import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-scroll';

//Mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


//Components
import { HeaderText } from './components/HeaderText'
import { AboutCompany } from './components/AboutCompany';
import { Space } from './components/Space';
import { Block } from './components/Block';

import image01 from "../assets/img/itpara_robots.png";
import image02 from "../assets/img/itpara_crm.png";
import image03 from "../assets/img/itpara_eshop.png";
import { Tehnology } from './components/Tehnology';
import imageTop from '../assets/img/itpara_top.png';

import Image from 'mui-image'

import { Button, ButtonProps, buttonClasses } from '@mui/base/Button';
import { styled, Theme } from '@mui/system';

import 'animate.css';

//-----------------------------------------------------------------------------------

//* Кнопка "Направления разработки"
const ButtonRoot = React.forwardRef(function ButtonRoot(props, ref) {
		const { children, ...other } = props;
	
		const [buttonW, setButtonW] = useState(getButtonWidth());

		function getButtonWidth() {
			return window.innerWidth > 768 ? 330 : 250; 
		}
	
		useEffect(() => {
			function handleResize() {
				setButtonW(getButtonWidth());
			}
			
			window.addEventListener('resize', handleResize);
			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}, []);

		const buttonH = 60


		return (
				<svg width={buttonW} height={buttonH} {...other} ref={ref}>
				<polygon points={`0,${buttonH} 0,0 ${buttonW},0 ${buttonW},${buttonH}`} className="bg" />
				<polygon points={`0,${buttonH} 0,0 ${buttonW},0 ${buttonW},${buttonH}`} className="borderEffect" />
				<foreignObject x="0" y="0" width={buttonW} height={buttonH}>
					<div className="content">{children}</div>
				</foreignObject>
			</svg>
		);
	});
	
	ButtonRoot.propTypes = {
		children: PropTypes.node,
	};
	
	const SvgButton = React.forwardRef(function SvgButton(props, ref) {
		return <Button {...props} slots={{ root: CustomButtonRoot }} ref={ref} />;
	});
	
	export default function UnstyledButtonCustom() {
		return <SvgButton>Направления разработки</SvgButton>;
	}
	
	const black = '#000000';
	const gray = '#cfcfcf';
	
	const CustomButtonRoot = styled(ButtonRoot)(
		({ theme }) => `
		overflow: visible;
		cursor: pointer;
		--main-color: ${theme.palette.mode === 'light' ? black : gray};
		--hover-color: ${theme.palette.mode === 'light' ? gray : black};
		--active-color: ${theme.palette.mode === 'light' ? gray : black};
	
		& polygon {
			fill: transparent;
			transition: all 800ms ease;
			pointer-events: none;
		}
	
		& .bg {
			stroke: var(--main-color);
			stroke-width: 1;
			filter: drop-shadow(4px 4px 0 ${black});  
			fill: rgb(255, 255, 255);
		}
	
		& .borderEffect {
			stroke: var(--main-color);
			stroke-width: 2;
			stroke-dasharray: 240 1200; /* Double the original value */
			stroke-dashoffset: 240; /* Double the original value */
			fill: transparent;
		}
	
		&:hover,
		&.${buttonClasses.focusVisible} {
			.borderEffect {
				stroke-dashoffset: -1200; /* Double the original value */
			}
	
			.bg {
				fill: var(--hover-color);
			}
		}
	
		&:focus,
		&.${buttonClasses.focusVisible} {
			outline: 2px solid ${theme.palette.mode === 'dark' ? gray : black};
			outline-offset: 2px;
		}
	
		&.${buttonClasses.active} {
			& .bg {
				fill: var(--active-color);
				transition: fill 150ms ease-out;
			}
		}
	
		& foreignObject {
			pointer-events: none;
	
			& .content {
				font-size: 16px;
				font-family: 'Russo One', sans-serif;
				font-weight: 600;
				line-height: 1.5;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: var(--main-color);
			}
	
			& svg {
				margin: 0 4px;
			}
		}`,
	);
//-----------------------------------------------------------------------------------






export const Content = () => {

	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
	const handleResize = () => {
	  setScreenWidth(window.innerWidth);
	};
  
	useEffect(() => {
	  window.addEventListener('resize', handleResize);
	  return () => window.removeEventListener('resize', handleResize);
	}, []);



		return (
				<Box>
					<HeaderText size="50px" sx={{ textAlign: screenWidth < 768 ? 'center !important' : 'initial' }}>
							Облачные решения, которые соединяют бизнес с клиентами через общение в мессенджерах
					</HeaderText>

					<Box display={screenWidth> 768 ? 'flex':'block'} pt={5} pb={7} >
						<Typography
							component="p"
							sx={{
								// textAlign: "left",
								fontSize: "20px",
								lineHeight: 1.6,
								letterSpacing: "0em",
								opacity: 1,
								width:screenWidth > 768 ?'50%':'100%',	
								textAlign: screenWidth < 768 ? 'center !important' : 'initial'
							}}
						>
								Привлекайте клиентов эффективно взаимодействуя с ними в самых популярных мессенджерах
						</Typography>
						<Box
							sx={{
								display: 'flex',
								justifyContent: screenWidth > 768 ? 'flex-end':'center',
								width:screenWidth> 768 ?'50%':'100%',	

							}}
						>   
							<Box
								sx={{
									display: 'flex',
									justifyContent: screenWidth > 768 ? 'flex-end':'center',
									// position:'absolute',
									position:screenWidth > 768 ? 'absolute':'relative',
									animation: "bounceInLeft 1s"
								}}
								className="animate__animated animate__bounceInLeft"
							>
								<Image  src={imageTop} width="50%" height="100%" showLoading={'false'}
									sx={{

										animation:'none!important',
										transition:'none!important',
									}}
								/>
									
							</Box>
						</Box> 

									
					</Box>

					<Box width={'100%'} sx={{ textAlign: screenWidth < 768 ? 'center' : 'initial' }}>
						<Link
							to="directions"
							spy={true}
							smooth={true}
							offset={-70} 
							duration={500}
						>
							<UnstyledButtonCustom />
						</Link>
					</Box>


					<Space />
					<Space />

					<AboutCompany  type={screenWidth < 996	 ? 'column' : 'row'}/>

					<Space />

					<Box id="directions">
						<HeaderText   size="40px" align="center">
								Направления деятельности
						</HeaderText>
					</Box>

					<Block
							number="01"
							title="Средства управления диалоговыми роботами (чат-боты)"
							description="Диалоговые роботы (чат-боты) предназначенные для обслуживания клиентов"
							image={image01}
							// type="row"
							type={screenWidth < 992 ? 'column' : 'row'}
					/>
					<Block
							number="02"
							title="Средства управления отношениями с клиентами"
							description="Автоматизация процессов обслуживания клиентов, сбор данных, планирование, проведение и анализ маркетинговых кампаний и программ лояльности"
							image={image02}
							// type="row-reverse"
							type={screenWidth < 992 ? 'column' : 'row-reverse'}
					/>
					<Block
							number="03"
							title="Средства электронной коммерции в мессенджерах"
							description="Создание и управление торговой площадкой (интернет магазином) внутри мессенджеров"
							image={image03}
							// type="row"
							type={screenWidth < 992 ? 'column' : 'row'}
					/>
					<Space />
					<Box id='tehnology'>
						<HeaderText size="48px" align="center">
								Технологии
						</HeaderText>	
					</Box>
					<Tehnology type={screenWidth < 768 ? 'column' : 'row'}/>
				</Box>
		)
}
