import React from 'react'

import Image from 'mui-image'

import { padding } from '@mui/system'
import { Box, Typography, useTheme } from '@mui/material'

export const TechnologyComponent = ({ image, background, title }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                height:'200px',
                width: "20%",
                display: "flex",
                flexDirection: "column",
                justifyContent: 'space-between',
                // padding:'35px',
                padding: theme.spacing(3),

            }}
        >
            <Box
                sx={{
                    padding: theme.spacing(3),
                    // padding:'35px',
                    // paddingTop: "70px",
                    // paddingLeft: "70px",
                    // paddingRight: "70px",
                    // paddingBottom: "30px",
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <Image src={image} width="100%" maxHeight="100%"  />
            </Box>
            <Typography
                component="h2"
                sx={{

                    textAlign: "center",
                    fontSize: "20px",
                    fontFamily: "Russo One",
                    lineHeight: "1.4",
                    opacity: 1,
                    letterSpacing: "0em",
                }}
            >
                {title}
            </Typography>
        </Box>
    )
}
