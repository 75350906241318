import { Box } from "@mui/material";
import React from "react";
import { TechnologyComponent } from "./TechnologyComponent";

import yandeximg from "../../assets/img/ydb.png";
import serverlessimg from "../../assets/img/serverless.png";

import react from "../../assets/img/react.webp";
import redux from "../../assets/img/redux.png";
import python from "../../assets/img/python.png";
import background from "../../assets/img/Vector.png";

export const Tehnology = ({ type }) => {
	return (
		<Box
			sx={{
				display: "flex",
				minWidth: "100%",
				maxWidth: "100%",
				flexDirection: type,
				justifyContent: "center",
			}}
		>
			<TechnologyComponent
				title="React"
				image={react}
				background={background}
			/>
			<TechnologyComponent
				title="Redux"
				image={redux}
				background={background}
			/>
			<TechnologyComponent
				title="Python"
				image={python}
				background={background}
			/>
			<TechnologyComponent
				title="Yandex DB"
				image={yandeximg}
				background={background}
			/>
			<TechnologyComponent
				title="Serverless"
				image={serverlessimg}
				background={background}
			/>
		</Box>
	);
};
