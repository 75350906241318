import React from "react";
import Logo from "../assets/img/icons8_code_file_64.png";

import { Link } from "react-scroll";

import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export const Header = () => {
	const [open, setOpen] = React.useState(false);
	const phoneNumber = "8 904 060 6086";

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
		// const phoneNumber = '8 904 060 6086';
		navigator.clipboard
			.writeText(phoneNumber)
			.then(() => console.log("Номер скопирован в буфер обмена"))
			.catch((err) => console.error("Ошибка копирования номера: ", err));
	};
	return (
		<Box
			sx={{
				width: "100%",
				display: "flex",
				// flexDirection: "row",
				paddingTop: "60px",
				paddingBottom: "10px",
				alignItems: "center",
				flexDirection: "column",

				"@media (min-width: 768px)": {
					flexDirection: "row",
					justifyContent: "center",
				},
			}}
		>
			<Box
				sx={{
					width: "100%",
					"@media (min-width: 768px)": {
						width: "30%",
						flexDirection: "row",
						justifyContent: "center",
					},
				}}
			>
				<Box display={"flex"} alignItems={"center"}>
					<Box>
						<img
							className=""
							src={Logo}
							alt="Company Icon"
							style={{ width: "58px", height: "58px" }}
						/>
					</Box>

					<Typography
						sx={{
							paddingLeft: "5px",

							textAlign: "left",
							fontFamily:
								"Helvetica Neue, Helvetica, Arial, sans-serif",
							fontSize: "14px",
							lineHeight: "1.8666666666666665",
							letterSpacing: "0.06666666666666667em",
							opacity: 0.75,
							marginTop: "5px",
						}}
					>
						Решения для бизнеса
					</Typography>
				</Box>
			</Box>

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					textAlign: "start",

					"@media (min-width: 768px)": {
						width: "40%",
						justifyContent: "center",
					},
					"@media (min-width: 450px)": {
						textAlign: "center",
						flexDirection: "row",
						justifyContent: "center",
					},
				}}
			>
				<Box
					ml={2}
					mr={2}
					sx={{
						textAlign: "center",
						"@media (max-width: 450px)": {
							paddingBottom: "30px",
						},
					}}
				>
					<Link
						to="directions"
						spy={true}
						smooth={true}
						offset={-70}
						duration={500}
					>
						<span>Направления деятельности</span>
					</Link>
				</Box>

				<Box ml={2} mr={2} sx={{ textAlign: "center" }}>
					<Link
						to="tehnology"
						spy={true}
						smooth={true}
						offset={-70}
						duration={500}
					>
						<span>Технологии</span>
					</Link>
				</Box>
			</Box>

			<Box
				sx={{
					width: "30%",
					display: "flex",
					justifyContent: "end",
					"@media (max-width: 850px)": {
						display: "none",
					},
				}}
			>
				{/* <Typography
					sx={{
						fontSize: "25px",
						color: "rgb(5, 35, 245) !important",
						fontFamily: "Russo One",
					}}
				>
					8 904 060 6086
				</Typography> */}
				<ClickAwayListener onClickAway={handleTooltipClose}>
					<div>
						<Tooltip
							PopperProps={{
								disablePortal: true,
							}}
							onClose={handleTooltipClose}
							open={open}
							disableFocusListener
							disableHoverListener
							disableTouchListener
							title="Скопирован"
						>
							<Button
								sx={{
									fontSize: "25px",
									color: "rgb(5, 35, 245) !important",
									fontFamily: "Russo One",
								}}
								onClick={handleTooltipOpen}
							>
								{phoneNumber}
							</Button>
						</Tooltip>
					</div>
				</ClickAwayListener>
			</Box>
		</Box>
	);
};
