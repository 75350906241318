import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { HeaderText } from './components/HeaderText'

import { Box, Typography, TextField, Button } from '@mui/material';

export const Footer = () => {

    const [email, setEmail] = useState('');

    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Отправлен email:', email);
        setEmail('');
    };


    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "black",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "60px",
                    paddingBottom: "60px",
                    color: "white",
                    // width: "1200px"
                }}
            >
                <Box
                    sx={{
                        width: "25%",
                        padding: "5px",
                    }}
                >
                    <Box display={'flex'}>

                        <Box>
                            <img
                                className=""
                                src="https://i.1.creatium.io/31/a7/3a/c0c08b12ad8adbcbdc7142d81a69caf6b1/icons8_code_file_64_kopiya.png#%7B%22size%22:%5B64,64%5D,%22quality%22:8%7D"
                                alt="Company Icon"
                                style={{ width: "58px", height: "58px" }}
                            />
                        </Box>


                        <Box
                            sx={{
                                paddingLeft: "5px"
                            }}
                        >
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    fontFamily: "Russo One",
                                    fontSize: "16px",
                                    lineHeight: 1,
                                    letterSpacing: '0em',
                                    opacity: 1,
                                    marginBottom: "5px",
                                }}
                            >
                                АЙТИПАРА
                            </Typography>

                            <Typography
                                sx={{
                                    textAlign: "left",
                                    fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                                    fontSize: "14px",
                                    lineHeight: "1.8666666666666665",
                                    letterSpacing: "0.06666666666666667em",
                                    opacity: 0.75,
                                    marginTop: "5px",
                                }}
                            >
                                Решения для бизнеса
                            </Typography>
                        </Box>
                    </Box>



                    <Typography
                        sx={{
                            textAlign: "left",
                            fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                            fontSize: "12px",
                            lineHeight: "1.8666666666666665",
                            letterSpacing: "0.06666666666666667em",
                            opacity: 0.45,
                            paddingTop: '10px',
                            paddingBottom: '10px',
                        }}
                    >
                        Полный цикл разработки web приложений для бизнеса 
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: "left",
                            fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                            fontSize: "12px",
                            lineHeight: "1.8666666666666665",
                            letterSpacing: "0.06666666666666667em",
                            opacity: 0.45,
                        }}
                    >
                        © 2021 «АЙТИПАРА. Все права защищены.
                    </Typography>
                   

                </Box>
                <Box
                    sx={{
                        width: "25%",
                        padding: "5px",
                    }}  
                >
                    <Typography
                        sx={{
                            textAlign: "left",
                            fontFamily: "Russo One",
                            fontSize: "12px",
                            lineHeight: "1.8666666666666665",
                            letterSpacing: "0.06666666666666667em",
                            opacity: 1,
                        }}
                    >
                        КОНТАКТЫ
                    </Typography>

                    <Typography

                        sx={{
                            textAlign: "left",
                            fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                            fontSize: "13px",
                            lineHeight: "1.8666666666666665",
                            letterSpacing: "0.06666666666666667em",
                            opacity: 0.45,
                        }}
                    >
                        atiparacom@gmail.com
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: "left",
                            fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                            fontSize: "13px",
                            lineHeight: "1.8666666666666665",
                            letterSpacing: "0.06666666666666667em",
                            opacity: 0.45,
                        }}
                    >
                        ОГРН: 1225200038755
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: "left",
                            fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                            fontSize: "13px",
                            lineHeight: "1.8666666666666665",
                            letterSpacing: "0.06666666666666667em",
                            opacity: 0.45,
                        }}
                    >
                        ИНН/КПП 5261133572/526101001
                    </Typography>

                    <Typography
                        sx={{
                            textAlign: "left",
                            fontFamily: "Russo One",
                            fontSize: "23px",
                            lineHeight: 1,
                            letterSpacing: '0em',
                            opacity: 1,
                            paddingTop: '10px',
                            paddingBottom: '10px',

                        }}
                    >
                        8(904)0606086
                    </Typography>

                </Box>
                <Box
                    sx={{
                        width: "25%",
                        padding: "5px",
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: "left",
                            fontFamily: "Russo One",
                            fontSize: "12px",
                            lineHeight: "1.8666666666666665",
                            letterSpacing: "0.06666666666666667em",
                            opacity: 1,
                        }}
                    >
                        НАШИ ПРОЕКТЫ
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            textAlign: "left",
                            fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                            fontSize: "12px",
                            lineHeight: "1.8666666666666665",
                            letterSpacing: "0.06666666666666667em",
                            opacity: 0.45,
                        }}
                    >
                        <a href="https://moysklad.itpara.ru/" style={{ color: "white" }} target="_blank" rel="noreferrer">
                            Интеграция "Мой склад"
                        </a>
                    </Typography>

                </Box>
                <Box
                    sx={{
                        width: "25%",
                        padding: "5px",
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: "left",
                            fontFamily: "Russo One",
                            fontSize: "12px",
                            lineHeight: "1.8666666666666665",
                            letterSpacing: "0.06666666666666667em",
                            opacity: 1,
                        }}
                    >
                        ПОДПИСКА НА ОБНОВЛЕНИЯ
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{
                            textAlign: "left",
                            fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                            fontSize: "12px",
                            lineHeight: "1.8666666666666665",
                            letterSpacing: "0.07em",
                            opacity: 0.45,
                        }}
                    >
                        Введите ваш email, чтобы всегда быть в курсе последних обновлений
                    </Typography>

                    <Link to="https://t.me/+BeWJPPXus2syOGNi" target="_blank">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ marginTop: '10px', borderRadius:'0' }}
                        >
                            Telegram
                        </Button>
                    </Link>

                    {/* <form onSubmit={handleSubmit}>
                        <Box display={'flex'}>

                             <TextField  
                                label="Email *" 
                                // marginsRight='10px'
                                sx={{
                                    marginRight:'15px',
                                    '& .MuiInputLabel-root': {
                                        color: '#ffffff', // Осветление текста метки
                                        opacity: '0.45'
                                    },
                                    '& .MuiInputBase-input': {
                                        color: '#ffffff', // Осветление текста в поле ввода
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'transparent', // Сделаем цвет рамки прозрачным
                                            borderBottom: '1px solid rgba(255, 255, 255, 0.45)', // Только нижняя граница
                                            borderRadius:'0'
                                        },
                                     },
                                }}
                             />

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{ marginTop: '10px', borderRadius:'0' }}
                            >
                                Отправить
                            </Button>

                        </Box>
                    </form> */}
                </Box>
            </Box>
        </Box>
    )
}
