import { Box, Typography } from '@mui/material'
import React from 'react'
import { HeaderText } from './HeaderText'
import Image from 'mui-image'

export const Block = ({ number, title, description, image, type }) => {
    return (
        <Box
            sx={{
                maxWidth: "1200px",
                display: "flex",
                flexDirection: type,
                marginBottom: "20px",
                marginTop: "80px",

            }}
        >
            <Box
                sx={{
                    minWidth: "50%",
                }}
            >
                <Typography
                    sx={{
                        textAlign: "left",
                        fontSize: "136px",
                        fontFamily: "Russo One",
                        opacity: "0.21",
                        lineHeight: "1.2",
                        '@media (max-width: 992px)': { 
                            fontSize: "80px",
                        },
                    }}
                >
                    {number}
                </Typography>
                <HeaderText
                sx={{
                    fontSize: "32px",
                    '@media (max-width: 992px)': { // Медиа-запрос для размеров экрана до 992px
                        fontSize: "26px",
                    },
                }}
                >
                    {title}
                </HeaderText>
                <Typography sx={{ marginTop: "20px", '@media (max-width: 992px)': { marginBottom: "20px" } }}>
                    {description}
                </Typography>
            </Box>

            <Box
                sx={{
                    minWidth: "50%",
                    maxWidth: "50%",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "center",
                    '@media (max-width: 992px)': {
                         maxWidth: "80%",

                        // width: '100%',
                    },
                }}
            >
                <Image width="75%" src={image} />
            </Box>
        </Box>
    )
}
