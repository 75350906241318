import { Suspense } from 'react'
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { Layout } from '../layout/Layout';
import { Error } from '../layout/Error';
import { Content } from '../layout/Content';

export const Route = () => {
    const routes = useRoutes([
        {
            path: "/",
            element: (
                <Layout>
                    <Suspense>
                        <Outlet />
                    </Suspense>
                </Layout>
            ),
            children: [
                { path: "/", element: <Content /> },
                { path: "404", element: <Error /> },
                { path: "*", element: <Navigate to="/404" replace /> }
            ]
        },
    ])

    return routes;
}
