import React from 'react'
import { Box, Typography } from '@mui/material'

export const TextCompany = ({ children }) => {
    return (
        <Box
            sx={{
                padding: "5px"
            }}
        >
            <Typography
                component="p"
                sx={{
                    textAlign: "left",
                    fontSize: "20px",
                    lineHeight: 1.5,
                    letterSpacing: "0em",
                    opacity: 1,
                    '@media (max-width: 992px)': {
                        fontSize: '16px',
                    }
                }}
            >
                {children}
            </Typography>
        </Box>
    )
}
