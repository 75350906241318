import React from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Container } from '@mui/material'
import { Space } from './components/Space'


export const Layout = ({ children }) => {
    return (
        <>
            <Container
                sx={{
                    maxWidth: "1200px",
                    marginRight: "auto",
                    marginLeft: "auto",
                    paddingLeft: "0",
                    paddingRight: "0",
                    position: "relative",
                    zIndex: "7",
                }}
            >
                <Header />
                <Space />
                {children}
            </Container>
            <Space />
            <Footer />
        </>
    )
}
