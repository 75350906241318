import './App.css';
import { Route } from './core/route/Route';


function App() {
  return (
    <Route />
  );
}

export default App;
